<template>
  <div class="desktopLayoutClass">
      <v-app>
        <div v-show="show_header" class="header"
          style="
            display: flex;
            flex-direction: row;
            padding-top: 10px;
            padding-left: 5px;
          "
        >
          <div style="display: flex; flex-direction: row; width: 100%">
            <div
              style="width: 10%; padding-top: 6px"
              align="left"
              v-on:click="redirectToHomePage()"
            >
              <img
                src="https://img.icons8.com/material-outlined/20/000000/left.png"
              />
            </div>
            <div style="width: 90%" align="left">
              <span style="font-weight: 600; font-size: 15pt"
                >{{ $t("Customer.CareCompanion.Care_Companion") }}</span
              >
            </div>
          </div>
        </div>

       <v-container >
         <div>
           <div>
             <v-card height="260px">
                <img height="150px" width="50vh" style="width:50%; padding-top:5px" src="https://s3iconimages.mymedicine.com.mm/care_companion_icon.svg"/>
                  <div>
                    <p>{{ $t("Customer.CareCompanion.Welcome") }}</p>
                  </div>
                  <a
                  href="tel:+959980635588"
                  style="color: white; text-decoration: none"
                  @click="requestCareCompanion()">
                  <div style="background:#1467BF; width:90%; margin: 0px auto; height:30px; font-weight:bolder; border-radius:8px; padding:2px">
                      {{ $t("Customer.CareCompanion.Call") }}
                      <!-- data-rel="external" -->
                    <!-- data-tel="+959980635588" -->
                  </div>
                </a>
             </v-card>
             <!-- <FacebookCustomerChatComponent></FacebookCustomerChatComponent> -->
           </div>
         </div>
        </v-container>
        <!-- <bottomnavbar style="position:sticky; bottom:0px; width: 100%;margin-bottom:1vh" :pageName=pageName :isLoggedIn=currentCustomer :kbzEmployee=kbzEmployee></bottomnavbar> -->
      </v-app>

  </div>
</template>

<script>
import NavBar from "../../../views/navbar.vue";
import bottomnavbar from "./bottomnavbar.vue";
import axios from "axios";
import FacebookCustomerChatComponent from '../../../components/facebookMessengerModal.vue'
export default {
  name: "careCompanion",
  components: {
    NavBar,
    FacebookCustomerChatComponent,
    bottomnavbar,
  },
  data() {
    return {
      pageName:"",
      kbzEmployee:false,
      auth_error_enable: false,
      error_enable: false,
      dialog: false,
      reason: "",
      customer_id: "",
      customer_name :"",
      phoneNumber : "",
      currentCustomer:"",
      description: "",
      enquiry_types: [],
      dependent_members: [],
      patient_name: "",
      isBookSlot: false,
      dependent_model: false,
      dependent_name: "",
      dependent_dob: "",
      dependent_weight: "",
      dependent_height: "",
      dependent_gender: "",
      enquiry_id: "",
      description: "",
      submit_button: "",
      gender: ["Male", "Female", "Other"],
      Relationship: ["Parent", "Child", "Spouse", "Sibling"],
      dependent_relationship: "",
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      isMobile : false,
      show_header : true,
    };
  },
  methods: {
    closeDialogUnauthorized(){
    this.auth_error_enable = false;
    this.$router.push({
        name: "Login",
      });
    },
    closeDialogError(){
    this.error_enable = false;
    },
    redirectToHomePage() {
      this.$router.go(-1);
    },
    addDependentMember() {
      this.dependent_model = true;
    },
    // saveDependentInfo() {
    //   var dependent_body = {
    //     dependentName: this.dependent_name,
    //     dependentCustomerRelation: this.dependent_relationship,
    //     dependentDob: this.dependent_dob,
    //     dependentHeight: this.dependent_height,
    //     dependentWeight: this.dependent_weight,
    //     dependentGender: this.dependent_gender,
    //     token : this.currentCustomer,
    //     typeOfUser : "CUSTOMER"
    //   };
    //   axios
    //     .post(
    //       process.env.VUE_APP_BACKEND_URL + "/createDependent",
    //       dependent_body
    //     )
    //     .then((saveDependentInfoResponse) => {
    //       var dependent_object = saveDependentInfoResponse.data.data;
    //       this.dependent_members.push({
    //         _id: dependent_object._id,
    //         dependent_name: dependent_object.dependent_name,
    //       });
    //     })
    //     .catch((saveDependentInfoError) => {
    //       if(saveDependentInfoError.response.status == 401){
    //         this.auth_error_enable = true;
    //       }
    //       else{
    //         this.error_enable = true;
    //       }
    //     });
    //   this.dependent_name = "";
    //   this.dependent_relationship = "";
    //   this.depdendent_dob = "";
    //   this.dependent_height = "";
    //   this.dependent_weight = "";
    //   this.dependent_gender = "";
    //   this.dialog = false;
    // },
    // getDependentMembers() {
    //   var customerData = {
    //     token : this.currentCustomer,
    //     typeOfUser : "CUSTOMER"
    //   };
    //   axios
    //     .post(
    //       process.env.VUE_APP_BACKEND_URL + "/fetchDependentFromCustomerId",
    //       customerData
    //     )
    //     .then((getDependentMembersResponse) => {
    //       this.dependent_members = getDependentMembersResponse.data.data;
    //       this.dependent_members.push({
    //         dependent_name: "Self",
    //       });
    //     })
    //     .catch((GetDependentMembersError) => {
    //       if(GetDependentMembersError.response.status == 401){
    //         this.auth_error_enable = true;
    //       }
    //       else{
    //         this.error_enable = true;
    //       }
    //     });
    // },
    // getEnquiryTypes() {
    //   var postData = {
    //     token : this.currentCustomer,
    //     typeOfUser : "CUSTOMER"
    //   }
    //   axios
    //     .post(
    //       process.env.VUE_APP_BACKEND_URL +
    //         "/readAllEnquiries",postData
    //     )
    //     .then((EnquiryList) => {

    //       this.enquiry_types = EnquiryList.data.data;
    //     })
    //     .catch((ReadEnquiryError) => {
    //       if(ReadEnquiryError.response.status == 401){
    //         this.auth_error_enable = true;
    //       }
    //       else{
    //         this.error_enable = true;
    //       }
    //     });
    // },
    checkLength() {
      if (this.description.length > 100) {
        alert("Size cannot be 100");
        this.description = this.description.slice(0, 100);
      }
    },
    // saveDependentInfo() {
    //   var dependent_body = {
    //     dependentName: this.dependent_name,
    //     dependentCustomerRelation: this.dependent_relationship,
    //     dependentDob: this.dependent_dob,
    //     dependentHeight: this.dependent_height,
    //     dependentWeight: this.dependent_weight,
    //     dependentGender: this.dependent_gender,
    //     token : this.currentCustomer,
    //     typeOfUser : "CUSTOMER"
    //   };
    //   axios
    //     .post(
    //       process.env.VUE_APP_BACKEND_URL + "/createDependent",
    //       dependent_body
    //     )
    //     .then((saveDependentInfoResponse) => {
    //       var dependent_object = saveDependentInfoResponse.data.data;
    //       this.dependent_members.push({
    //         _id: dependent_object._id,
    //         dependent_name: dependent_object.dependent_name,
    //       });
    //     })
    //     .catch((saveDependentInfoError) => {
    //       if(saveDependentInfoError.response.status == 401){
    //         this.auth_error_enable = true;
    //       }
    //       else{
    //         this.error_enable = true;
    //       }
    //     });
    //   this.dependent_name = "";
    //   this.dependent_relationship = "";
    //   this.dependent_dob = "";
    //   this.dependent_height = "";
    //   this.dependent_weight = "";
    //   this.dependent_gender = "";
    //   this.dialog = false;
    // },
    requestCareCompanion() {
      var carecompanion_body = {
        calledForName: this.patient_name,
        enquiryId: this.enquiry_id,
        enquiryName: this.enquiry_id,
        customerDescription: this.description,
        phoneNumber: this.phoneNumber,
        token : this.currentCustomer,
        typeOfUser : "CUSTOMER"
      };
      // axios
      // .post(
      //   process.env.VUE_APP_BACKEND_URL + "/createCareCompanionSchedule",
      //   carecompanion_body
      // )
      // .then((requestCareCompanionResponse) => {
      //   this.customer_id = "";
      //   this.enquiry_id = "";
      //   this.description = "";
      //   this.patient_name = "";
      // })
      // .catch((requestCareCompanionResponseError) => {
      //   if(requestCareCompanionResponseError.response.status == 401){
      //     this.auth_error_enable = true;
      //   }
      //   else{
      //     this.error_enable = true;
      //   }
      // });
    },
    userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                pageName : 'careCampanionPage',
                typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                pageName: 'careCampanionPage',
                typeOfUser: 'CUSTOMER',
                token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
    },
  },
  mounted() {
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    this.isMobile = this.$device.mobile;
    if(this.$store.state.locale == 'en')
      document.title = "Care Companion"
    else
      document.title = 'ဆေးဝါးပညာရှင်'
    this.currentCustomer = this.$cookies.get("customerToken");
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    if(this.$store.state.employee !== "")
    {
      this.kbzEmployee = this.$store.state.employee;
    }
    if(!this.currentCustomer){
      this.$router.push({
        name : "Login"
      })
    }
    else{
      //logging page hit - start
      let x =  localStorage.getItem("currentURL")
      let pageName = "CareCompanionScreen";
      if( x == pageName)
        x = "";
      localStorage.setItem("currentURL", pageName)
      console.log(document.referrer);
      var pageAccessBody = {
        token: this.currentCustomer,
        pageName: pageName,
        previousPage: x ? x : "START"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody)
      .then((logSuccess) =>{

      })
      .catch((logError) =>{

      });
      //logging page hit - end
        var verifyCustomerTokenData = {
          "token" : this.currentCustomer,
          "typeOfUser" : "CUSTOMER"
        }
        axios.post(process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",verifyCustomerTokenData)
        .then((verifyCustomerTokenDataResponse) => {
          // this.customer_id = verifyCustomerTokenDataResponse.data.data._id;
          this.customer_name = verifyCustomerTokenDataResponse.data.data.customer_name;
          this.phoneNumber = verifyCustomerTokenDataResponse.data.data.phoneNumber;
          // this.getDependentMembers();
          this.userPageViewTable();
        })
        .catch((verifyCustomerTokenDataError)=>{
          console.log(verifyCustomerTokenDataError)
          if(verifyCustomerTokenDataError.response.status == 401){
            this.auth_error_enable = true;
          }
          else{
            this.error_enable = true;
          }
        })
    	}
  },
};
</script>

<style>
#myselect .v-select {
  height: 0px;
}
.header{
  display: block;
  /* box-shadow: 0px 2px 15px 0px #00000026; */
  padding: 16px 20px !important;
  box-shadow: 0px 2px 15px 0px #00000026;

}
.dependentIcon{
 width: 48px !important;
 margin-top: -12px !important;
}

/* @media (min-width: 800px){
  .careCompanionMainDiv{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }} */
</style>